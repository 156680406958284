import useSendAnalyticsEvent from "lib/hooks/useAnalytics";
import { AppliedCouponType, AppliedGiftCardsType } from "lib/contexts/CheckoutProvider";
import {
  ItemType,
  BillingType,
  ShippingHandlesType,
  ShippingHandleType,
  LoyaltyRedemptionHandle,
  FloWalletRedemptionHandle,
  ThirdPartyAuthType,
  BannerConfigType,
  CheckoutUIMetadataType,
  PrimaryCTAConfigType,
} from "lib/types/checkout";
import { getUTMParamsFromURL, isEmptyObj, publishPostMessage, setClarityTag } from "lib/utils/helpers";
import { analyticsEvents, analyticsTypes, clartityTags, constants, eventTypes } from "lib/utils/constants";
import { getRequest, postRequest, putRequest } from "lib/core/apiClient";
import { isTruecallerEnabled } from "lib/third-party/truecaller";
import { getIsTwoStepCheckout } from "lib/utils/merchant";
import Cookies from "js-cookie";
import { customBranding } from "lib/utils/customBranding";
import { UserLoginType } from "lib/types/user";
import { CheckoutContext } from "lib/contexts/CheckoutProvider";
import { currencyFormatter } from "lib/utils/formatters";
import { t } from "i18next";
import { MerchantContext } from "lib/contexts/MerchantProvider";
import { UserContext } from "lib/contexts/UserProvider";
import { AuthContext } from "lib/contexts/AuthProvider";
import { mutate } from "swr";
import { parseCartResponse, DEFAULT_PROGRESS_BAR } from "@shopflo/ui";

export const parseItemsFromURL = (data: any) => {
  const items: any = JSON.parse(data ?? "{}");

  if (!Boolean(items?.length)) return;

  for (let i = 0; i < items.length; i++) {
    items[i].item_id = items[i].item_id?.toString();
    items[i].price = (Math.round(items[i].price) / 100)?.toFixed(2);
  }
  return items;
};

/**
 * @param {[any]} data    Items data from API response
 * @param {boolean} inventoryCheck   flag that changes the items quantity based on the available inventory
 *
 * @returns {items[]}  Returns a parsed array of items
 */
export const getItems = (data: any, inventoryCheck: boolean = false) => {
  if (!Boolean(data?.length)) return [];
  let items: ItemType[] = [];
  data.forEach((value: any) => {
    let quantity = value.quantity;
    const isAvailable = Boolean(value.status === "AVAILABLE");
    if (!Boolean(isAvailable) && Boolean(inventoryCheck)) {
      quantity = value.inventory_quantity >= 0 ? value.quantity - value.inventory_quantity : value.quantity;
    }
    const item: ItemType = {
      item_id: value.id,
      variant_id: value.item_id,
      price: value.line_price?.total ?? value.price,
      quantity: quantity,
      item_title: value.item_data?.product_name,
      originalPrice: value.item_data?.original_price ?? value.price,
      markupPrice: value.price,
      image: value.item_data?.image,
      is_freebie: value.is_freebie,
      variant_title: value.item_data?.variant_name,
      is_available: isAvailable ?? false,
      item_properties: isEmptyObj(value.properties) ? value.item_data?.properties : value.properties,
      availableQuantity: value.inventory_quantity ?? 0,
      isMutable: value.item_data?.is_mutable ?? true,
      product_id: value.product_id ?? "",
      item_url: value.item_data?.url ?? "",
      is_discount_alteration: value.is_discount_alteration ?? false,
    };
    const alreadyPresentIndex = items.findIndex(
      (selectedItem: ItemType) =>
        selectedItem?.price === item?.price && selectedItem?.variant_id === item?.variant_id,
    );
    if (alreadyPresentIndex > -1 && Boolean(value.is_freebie)) {
      items[alreadyPresentIndex].quantity = items[alreadyPresentIndex].quantity + quantity;
      return;
    }
    items.push(item);
  });
  return items;
};

export const getBillingDetails = (data: any) => {
  if (!Boolean(data)) return;
  const BillingType: BillingType = {
    total_payable: data.total_payable,
    sub_total: data.sub_total,
    discount: data.discount,
    tax: data.tax,
    rewards: data?.rewards,
    shipping: data.shipping,
    total: data.total,
    cod: data.cod,
    taxes_included: data.taxes_included,
    cod_enabled: data.cod_enabled,
    serviceable: data.serviceable,
    prepaid_total: data.total,
    prepaid_discount: data.prepaid_discount ?? data.total,
    original_sub_total: data.original_sub_total ?? data.sub_total,
  };

  return BillingType;
};

export const getCouponData = (discounts: any) => {
  if (!Boolean(discounts) || !Boolean(discounts?.length)) return [];
  const couponData = discounts
    ?.filter(
      (coupon: any) =>
        !Boolean(coupon?.is_prepaid) &&
        (coupon?.coupon_metadata?.coupon_type !== "REWARD_AS_DISCOUNT" ||
          (coupon?.coupon_metadata?.coupon_type === "REWARD_AS_DISCOUNT" &&
            coupon?.coupon_metadata?.coupon_reward_config?.wallet_type === "CRED")),
    )
    .map((coupon: any, index: number) => {
      const discountPercentage = coupon?.coupon_metadata?.concession_amount;
      const data: AppliedCouponType = {
        code: coupon?.discount_code,
        title: coupon?.coupon_metadata?.title ?? "",
        autoApplied: coupon?.auto_applied ?? false,
        isManualCode: false,
        isFreebie: coupon?.is_freebie ?? false,
        discountValue: coupon?.discount,
        isRemovable: coupon?.is_removable ?? false,
        freebieItemCount: coupon?.coupon_metadata?.item_count ?? 0,
        isPrepaid: coupon?.is_prepaid ?? false,
        prepaidValue: coupon?.coupon_metadata?.max_discount ?? 0,
        discountPercentage,
        isReward: coupon?.coupon_metadata?.coupon_type === "REWARD_AS_DISCOUNT",
        couponRewardConfig: coupon?.coupon_metadata?.coupon_reward_config,
        rewardData: {
          header: coupon?.coupon_metadata?.header,
          description: coupon?.coupon_metadata?.description,
          longDescription: coupon?.coupon_metadata?.long_description,
        },
      };
      return data;
    });
  return couponData;
};

export const getGiftCardData = (rewards: AppliedGiftCardsType[]) => {
  if (!Boolean(rewards) || !Boolean(rewards?.length)) return [];
  const giftCardData = rewards
    ?.filter((reward: any) => reward.type === "GC")
    .map((giftCard: any) => {
      const data: AppliedGiftCardsType = {
        cardId: giftCard?.src_reference_id,
        cardValue: giftCard?.amount,
        displayName: giftCard?.metadata?.code,
      };
      return data;
    });
  return giftCardData;
};

export const getAppliedLoyaltyData = (rewards: any) => {
  if (!Boolean(rewards) || !Boolean(rewards?.length)) {
    const data: LoyaltyRedemptionHandle = {
      id: "",
      title: "",
      desc: "",
      totalPoints: 0,
      totalPrice: 0,
      provider: "NONE",
    };
    return data;
  }
  const loyaltyData = rewards?.filter((reward: any) => reward.type === "LOYALTY")[0];
  const data: LoyaltyRedemptionHandle = {
    id: loyaltyData?.metadata?.redemption_id,
    title: "",
    desc: "",
    totalPoints: loyaltyData?.metadata?.points,
    totalPrice: loyaltyData?.amount,
    provider: loyaltyData?.provider,
  };
  return data;
};

export const getAppliedFloWalletData = (rewards: any) => {
  if (!Boolean(rewards) || !Boolean(rewards?.length))
    return {
      earnMultiplier: 1,
      totalReducedPoints: 0,
      totalReductionAmount: 0,
    };
  const walletData = rewards?.filter((reward: any) => reward.type === "WALLET")[0];
  const data: FloWalletRedemptionHandle = {
    earnMultiplier: 1,
    totalReducedPoints: walletData?.metadata?.points,
    totalReductionAmount: walletData?.amount,
  };
  return data;
};

/**
 * @param {AppliedCouponType[]} appliedCoupons   Applied coupons list
 *
 * @returns   true if there is at least one autoapplied coupon otherwise false.
 */

export const hasAutoAppliedCoupon = (appliedCoupons: AppliedCouponType[]) => {
  return Boolean(appliedCoupons?.find((coupon) => coupon?.autoApplied === true));
};

/**
 * @param {[any]} allCoupons   All coupons list
 *
 * @returns {string}  Returns the Coupond ID of the matched coupon code
 */
export const findIdCouponByCode = (coupons: any[], code: string) => {
  if (!Boolean(coupons?.length) || !Boolean(code)) return;

  const matchedCoupon = coupons.find((coupon: any) => coupon.coupon_details?.coupon_code === code);
  if (!Boolean(matchedCoupon)) return;
  return matchedCoupon?.coupon_details?.coupon_id;
};

export const getDefaultShippingHandle = (handles: ShippingHandlesType) => {
  if (!Boolean(handles) || !Boolean(handles.length)) return;

  let defaultHandle: ShippingHandleType = { ...handles[0] };
  handles.forEach((handle: ShippingHandleType) => {
    if (Boolean(handle.selected_handle)) {
      defaultHandle = { ...handle };
    }
  });
  return defaultHandle;
};

export const getCODShippingHandles = (handles: ShippingHandlesType) => {
  if (!Boolean(handles) || !Boolean(handles.length)) return [];
  return handles.filter((handle) => handle?.cod_enabled);
};

/**
 * @param {[any]} allCoupons    All coupons list
 * @param {string} couponCode   Coupon code whose stackability should be checked
 *
 * @returns {boolean}  Returns if the given coupon code is stackable or not
 */
export const isCouponStackable = (coupons: any, couponCode: string) => {
  if (!Boolean(coupons?.length) || !Boolean(couponCode)) return false;

  const couponData = coupons?.find((coupon: any) => coupon?.coupon_details?.coupon_code === couponCode);
  return Boolean(couponData?.un_applicability_reason?.reason === "UARC_002");
};

/**
 * @param {[any]} allCoupons    All coupons list
 *
 * @returns {number}  Returns the maximum discount amount possible. This only considers Shopflo discounts.
 * @returns {string}  Returns the maximum discount code possible. This only considers Shopflo discounts.
 */
export const getMaxCouponDiscount = (coupons: any) => {
  let maxCouponDiscountAmount: number = 0;
  let maxCouponDiscountCode: string = "";
  if (!Boolean(coupons?.length)) return { maxCouponDiscountAmount, maxCouponDiscountCode };
  coupons.forEach((coupon: any) => {
    if (
      (coupon.is_applicable || coupon?.un_applicability_reason?.reason === "UARC_002") &&
      coupon.coupon_details?.tags?.includes("SHOPFLO")
    ) {
      if (maxCouponDiscountAmount < coupon.total_discount) {
        maxCouponDiscountAmount = coupon.total_discount;
        maxCouponDiscountCode = coupon.coupon_details?.coupon_code;
      }
    }
  });
  return { maxCouponDiscountAmount, maxCouponDiscountCode };
};

export const getTotalItems = (cartItems: any) => {
  if (!Boolean(cartItems)) return 0;
  let count = 0;
  cartItems?.forEach((cartItem: any) => (count += cartItem.quantity));
  return count;
};

/**
 * @param {any} itemProperties    List of all the item level properties from Shopify Cart.js API
 * @param {string[]} merchantItemProperties    Properties that a merchant would like to show under each line item in checkout
 *
 * @returns {string}  Returns String of item level properties
 */
export const getItemPropertiesValues = (
  itemProperties: any,
  merchantItemProperties: string[],
  showAllItemProperties: boolean,
) => {
  if (isEmptyObj(itemProperties)) return "";
  let parsedItemProperties: string = "";
  const filteredItemProperties = getFileredItemProperties(
    itemProperties,
    merchantItemProperties,
    showAllItemProperties,
  );

  for (let key in filteredItemProperties) {
    if (filteredItemProperties.hasOwnProperty(key)) {
      parsedItemProperties += `${filteredItemProperties[key]} / `;
    }
  }

  parsedItemProperties = parsedItemProperties.trim();
  if (parsedItemProperties.charAt(parsedItemProperties.length - 1) === "/") {
    parsedItemProperties = parsedItemProperties.slice(0, -2);
  }
  return parsedItemProperties;
};

export const getFileredItemProperties = (
  itemProperties: any,
  merchantItemProperties: string[],
  showAllItemProperties: boolean,
) => {
  if (showAllItemProperties) return itemProperties;
  const filteredItemProperties: any = {};
  merchantItemProperties?.forEach((property: string) => {
    const matchedProperty = itemProperties[property];
    if (Boolean(matchedProperty)) {
      filteredItemProperties[property] = matchedProperty;
    }
  });
  return filteredItemProperties;
};

export const parseThirdpartyAuthReq = (data: any) => {
  const isThirdPartyAuthEnabled = data?.external_auth_enabled;
  if (!isThirdPartyAuthEnabled) return [];
  const parsedData: ThirdPartyAuthType = {
    provider: data?.auth_provider,
    redirectUrl: data?.metadata?.deep_link_url,
    requestId: data?.request_id,
  };
  return [parsedData];
};

/**
 * Publishes a cart update event to the parent window using postMessage.
 *
 * @param {Array} cartItems - An array of cart items to publish.
 * @param {string} cartItems.item_id - The unique identifier for the cart item.
 * @param {number} cartItems.quantity - The quantity of the cart item.
 * @returns {void}
 */
export const publishCartUpdate = (cartItems: any) => {
  try {
    if (!cartItems || !Array.isArray(cartItems)) return;
    const publishPayload: any = [];
    cartItems.forEach((item: any) => {
      const itemPayload = {
        id: item.variant_id,
        quantity: item.quantity,
        productId: item.product_id,
        price: item.price,
        isFreebie: item.is_freebie ?? false,
      };
      publishPayload.push(itemPayload);
    });
    publishPostMessage(eventTypes.CART_UPDATE, { items: publishPayload });
  } catch (e) {
    console.error(e);
  }
};

export const publishItemUpdate = (
  itemId: any,
  cartItems: any,
  type: "increment" | "decrement" | "delete",
  isCartItem: boolean = false,
) => {
  try {
    if (itemId < 0 || !cartItems) return;
    const itemData = cartItems.find((item: any) => parseInt(item.item_id) === parseInt(itemId));
    if (!itemData) return;
    const publishPayload: any = {
      id: itemData.variant_id,
      quantity: type === "delete" ? 0 : itemData.quantity,
      productId: itemData.product_id,
      price: itemData.price,
      isFreebie: itemData.is_freebie ?? false,
      shopfloId: itemData.item_properties?.shopflo_id ?? null,
    };
    publishPostMessage(eventTypes.ITEM_UPDATE, { item: publishPayload, isCartItem });
  } catch (e) {
    console.error(e);
  }
};

export const getBannerConfig = (data: any) => {
  const config = data?.banner_config;
  if (!Array.isArray(config)) return;
  if (!Boolean(config)) return;
  let bannerConfig: BannerConfigType[] = [];
  config.forEach((value: any) => {
    const bannerValue: BannerConfigType = {
      type: value.type,
      bagroundColor: value.background_color,
      enabled: value.enabled,
      htmlString: value.html_text_string,
      textColor: value.text_color,
    };
    bannerConfig.push(bannerValue);
  });
  return bannerConfig;
};

export const getProgressBarConfig = (values: any) => {
  if (!values || isEmptyObj(values)) return DEFAULT_PROGRESS_BAR;
  const { amountBased, countBased} = parseCartResponse({ CART: values })
  const activeProgressBar = amountBased.isEnabled ? amountBased : countBased
  return activeProgressBar
}
export const getPrimaryCtaConfig = (data: any) => {
  const config = data?.primary_cta_config;
  if (!Array.isArray(config)) return;
  if (!Boolean(config)) return;
  let primaryCtaConfig: PrimaryCTAConfigType[] = [];
  config.forEach((value: any) => {
    const primaryCtaValue: PrimaryCTAConfigType = {
      type: value.type,
      ctaText: value.cta_text,
    };
    primaryCtaConfig.push(primaryCtaValue);
  });
  return primaryCtaConfig;
};

export const parseCheckoutUIMetadata = (values: any) => {
  if (!values || isEmptyObj(values)) return;
  const parsedData: CheckoutUIMetadataType = {
    bannerConfig: getBannerConfig(values),
    upsellConfig: {
      isEnabled: values?.upsell?.is_enabled ?? false,
      title: values?.upsell?.upsell_title ?? "",
      discountedTitle: values?.upsell?.discounted_upsell_title ?? "",
    },
    layout: {
      available_elements: values?.layout?.available_elements ?? undefined,
      allowLogout: values?.layout?.allow_logout ?? true,
      items_mutable: values?.layout?.items_mutable ?? false,
      enableMarketingFlag: values?.layout?.enable_marketing_flag ?? true,
    },
    progressBarConfig: values?.progress_bar_attributes
      ? getProgressBarConfig(values.progress_bar_attributes)
      : DEFAULT_PROGRESS_BAR,
    primaryCTAConfig: getPrimaryCtaConfig(values),
  };
  return parsedData;
};

export const initializeCheckout = async ({
  tokenId,
  checkoutIdParam,
  hashParam,
  authContext,
  userContext,
  checkoutContext,
  merchantContext,
  analytics,
  isCheckoutInteractive,
}: {
  tokenId: string | null;
  checkoutIdParam: string | null;
  hashParam?: string;
  authContext: AuthContext;
  userContext: UserContext;
  checkoutContext: CheckoutContext;
  merchantContext: MerchantContext;
  analytics: ReturnType<typeof useSendAnalyticsEvent>;
  isCheckoutInteractive?: boolean;
}) => {
  if (!Boolean(tokenId)) return;
  const {
    actions: { setUserData, setUserLoginType },
  } = userContext;
  const {
    state: { isAuthenticated },
    actions: { setThirdPartyAuth, setLockDiscounts },
  } = authContext;
  const {
    actions: { checkoutInit, setCheckoutExpired },
    state: { checkoutId },
  } = checkoutContext;
  const {
    actions: { setMerchantData, setAddressConfig },
  } = merchantContext;
  const {
    setThirdPartyAnalyticsData,
    setCheckoutChannel,
    setClientMetadata,
    setLongSessionId,
    sendAnalyticsEvent,
  } = analytics;

  const utmParams = getUTMParamsFromURL();
  try {
    let checkoutTokenResponse: any;
    if (isAuthenticated) {
      checkoutTokenResponse = Boolean(checkoutId || checkoutIdParam)
        ? await putRequest(`/checkout/v2/checkout`, {
            uid: checkoutId || checkoutIdParam,
            ...(utmParams?.length && { note_attributes: utmParams }),
          })
        : await postRequest(`/checkout/v2/checkout-tokens/${tokenId}/checkout`, {
            ...(utmParams?.length && { note_attributes: utmParams }),
          });
    } else {
      const promises = [
        checkoutIdParam
          ? putRequest(`/v1/cart/${checkoutId || checkoutIdParam}/checkout`, {}, "CHECKOUT_PUBLIC")
          : postRequest(
              `/v2/checkout-tokens/${tokenId}/checkout`,
              { ...(utmParams?.length && { note_attributes: utmParams }) },
              "CHECKOUT_PUBLIC",
            ),
      ];
      if (isTruecallerEnabled()) {
        promises.push(postRequest(`/auth-callback/initiate`, { token_id: tokenId }, "AUTH"));
      }
      const responses = await Promise.allSettled(promises);
      if (responses[0]?.status === "rejected") {
        if (
          responses[0].reason.response.status === 400 &&
          (responses[0].reason.response.data.error === "C2P flow timedout" ||
            responses[0].reason.response.data.error === "C2P is already completed")
        ) {
          setCheckoutExpired(true);
        }
        return;
      }
      checkoutTokenResponse = responses[0].value;
      const isTwoStepCheckout =
        getIsTwoStepCheckout(checkoutTokenResponse?.merchant_attributes) &&
        Boolean(Cookies.get(constants?.TWO_STEP_AUTH_COOKIE_CLIENT));
      const isCheckoutC2P = Boolean(checkoutTokenResponse?.checkout?.metadata?.c2p?.enabled);

      if (
        (Boolean(hashParam?.length) && (hashParam === "#COD" || hashParam === "#PAYMENTS")) ||
        (isTwoStepCheckout && isCheckoutC2P)
      ) {
        checkoutTokenResponse = await postRequest(`/checkout/v2/checkout-tokens/${tokenId}/checkout`, {});
      }
      if (Boolean(responses[1]) && responses[1]?.status === "fulfilled") {
        const parsedAuthData = parseThirdpartyAuthReq(responses[1].value);
        setThirdPartyAuth(parsedAuthData);
      }

      const prefillPhone = checkoutTokenResponse?.checkout?.metadata?.user_details?.phone;
      if (prefillPhone) {
        setUserData({ phone: prefillPhone });
      }
    }

    if (!checkoutTokenResponse) return;
    checkoutInit(checkoutTokenResponse);

    const checkoutResponse = checkoutTokenResponse?.checkout;
    setClarityTag(clartityTags.MERCHANT_ID, checkoutResponse?.merchant_id);
    setClarityTag(clartityTags.CHANNEL, checkoutResponse?.metadata?.application_id);

    const accountResponse = checkoutTokenResponse?.customer_attributes ?? {};

    const merchantAttributesResponse = checkoutTokenResponse?.merchant_attributes;
    setMerchantData(merchantAttributesResponse);

    const attributes = merchantAttributesResponse?.account_attributes?.attributes;
    setClarityTag(clartityTags.SHOP_NAME, attributes?.shop_name);

    const ui: any = attributes?.ui;
    if (Boolean(ui)) {
      customBranding(
        ui?.color_pallet?.primary_color,
        ui?.color_pallet?.secondary_color,
        ui?.color_pallet?.primary_text_color,
      );
    }

    const allowedAddressFields: any = checkoutTokenResponse?.merchant_address_config?.config?.fields;
    setAddressConfig(allowedAddressFields);

    const tokenData: any = checkoutTokenResponse?.token;

    const analyticsMeta = checkoutResponse?.metadata?.analytics;
    setThirdPartyAnalyticsData(analyticsMeta);
    setCheckoutChannel(checkoutResponse?.metadata?.application_id);
    setClientMetadata(checkoutResponse?.metadata?.client_meta);
    setLongSessionId(checkoutResponse?.metadata?.long_session_id);
    let items: any = getItems(checkoutResponse.items);

    if (!isCheckoutInteractive) {
      if (Cookies.get(constants.REFRESH_TOKEN_COOKIE_CLIENT)) {
        setUserLoginType(analyticsEvents.FLO_COOKIE_LOGIN as UserLoginType);
        sendAnalyticsEvent({
          eventName: analyticsEvents.FLO_COOKIE_LOGIN,
          eventFor: [analyticsTypes.SF_ANALYTICS, analyticsTypes.FACEBOOK_PIXEL],
          eventType: "flo_action",
          metaData: {
            brandUrl: merchantAttributesResponse?.account_attributes?.attributes?.website_url,
            merchantId: merchantAttributesResponse?.uid,
            checkoutToken: tokenId,
            checkoutId: checkoutResponse?.uid,
            shopifySessionId: tokenData?.sf_session_id,
            userType: analyticsEvents.FLO_COOKIE_LOGIN,
            userData: {
              type: analyticsEvents.FLO_COOKIE_LOGIN,
              uid: accountResponse?.uid,
              email: accountResponse?.account_attributes?.email,
              phone: accountResponse?.phone,
              name: accountResponse?.account_attributes?.attributes?.name,
              user_id: accountResponse?.uid,
            },
            isFbqEnabled: merchantAttributesResponse?.analytics?.facebook?.enabled ?? false,
            fbqPixelId: merchantAttributesResponse?.analytics?.facebook?.pixel_id ?? [],
            checkoutItems: items,
            totalPayable: getBillingDetails(checkoutResponse.pricing)?.total_payable,
          },
        });
      } else if (Cookies.get(constants.AUTH_COOKIE_CLIENT)) {
        setUserLoginType(analyticsEvents.FLO_GUEST_LOGIN as UserLoginType);
        sendAnalyticsEvent({
          eventName: analyticsEvents.FLO_GUEST_LOGIN,
          eventFor: [analyticsTypes.SF_ANALYTICS],
          eventType: "flo_action",
          metaData: {
            brandUrl: merchantAttributesResponse?.account_attributes?.attributes?.website_url,
            merchantId: merchantAttributesResponse?.uid,
            checkoutToken: tokenId,
            checkoutId: checkoutResponse?.uid,
            shopifySessionId: tokenData?.sf_session_id,
            userData: {
              type: analyticsEvents.FLO_GUEST_LOGIN,
            },
            checkoutItems: items,
          },
        });
      }

      sendAnalyticsEvent({
        eventName: analyticsEvents.FLO_CHECKOUT_UI_LOADED,
        eventType: "load",
        metaData: {
          brandUrl: merchantAttributesResponse?.account_attributes?.attributes?.website_url,
          merchantId: merchantAttributesResponse?.uid,
          checkoutId: checkoutResponse.uid,
          checkoutToken: checkoutResponse?.checkout_token_id,
          shopifySessionId: tokenData?.sf_session_id,
          checkoutItems: items,
        },
      });

      const utmParams = getUTMParamsFromURL();

      if (utmParams?.length) {
        sendAnalyticsEvent({
          eventName: analyticsEvents.FLO_STORE_PAGE_VIEW,
          eventType: "load",
          metaData: {
            storeDetails: {
              utmParams: utmParams,
            },
            merchantId: merchantAttributesResponse?.uid,
            checkoutId: checkoutResponse.uid,
            checkoutToken: checkoutResponse?.checkout_token_id,
            shopifySessionId: tokenData?.sf_session_id,
            userData: {
              type: analyticsEvents.FLO_GUEST_LOGIN,
            },
          },
        });
      }
      isCheckoutInteractive = true;
    }

    // if (isAuthenticated) {
    //   //Updates ACR UTM Parameters. Async call, won't affect checkout speed
    //   sendCheckoutUTMParameters(checkoutResponse.uid);
    // }

    return {
      checkoutResponse,
      accountResponse,
      tokenData,
      isCheckoutInteractive,
    };
  } catch (error: any) {
    throw error;
  } finally {
    setLockDiscounts(false);
  }
};

export const initializeCart = async ({
  tokenId,
  hashParam,
  authContext,
  userContext,
  checkoutContext,
  merchantContext,
  analytics,
  isCheckoutInteractive,
  isUpdate,
}: {
  tokenId: string | null;
  hashParam?: string;
  authContext: AuthContext;
  userContext: UserContext;
  checkoutContext: CheckoutContext;
  merchantContext: MerchantContext;
  analytics: ReturnType<typeof useSendAnalyticsEvent>;
  isCheckoutInteractive?: boolean;
  isUpdate?: boolean;
}) => {
  if (!Boolean(tokenId)) return;
  const {
    actions: { setUserData },
  } = userContext;
  const {
    state: { isAuthenticated },
    actions: { setThirdPartyAuth, setLockDiscounts },
  } = authContext;
  const {
    actions: { checkoutInit, setCoupons },
  } = checkoutContext;
  const {
    actions: { setMerchantData, setAddressConfig },
  } = merchantContext;
  const { setThirdPartyAnalyticsData, setCheckoutChannel, setClientMetadata, setLongSessionId } = analytics;

  const utmParams = getUTMParamsFromURL();
  try {
    let checkoutTokenResponse: any;
    if (isAuthenticated) {
      checkoutTokenResponse = await postRequest(`/checkout/v1/checkout-tokens/${tokenId}/cart`, {});
      const checkoutId = checkoutTokenResponse?.checkout?.uid;
      if (isUpdate) {
        const discountResponse = await getRequest(`/checkout/v1/checkout/${checkoutId}/discounts`);
        setCoupons(discountResponse);
      }
      const { checkoutUIMetadata } = checkoutContext.state;
      if (Boolean(checkoutUIMetadata?.upsellConfig?.isEnabled)) {
        mutate(`/checkout/v1/checkout/${checkoutId}/upsell`);
      }
    } else {
      const promises = [
        postRequest(
          `/v1/checkout-tokens/${tokenId}/cart`,
          { ...(utmParams?.length && { note_attributes: utmParams }) },
          "CHECKOUT_PUBLIC",
        ),
      ];

      if (isTruecallerEnabled()) {
        promises.push(postRequest(`/auth-callback/initiate`, { token_id: tokenId }, "AUTH"));
      }

      const responses = await Promise.allSettled(promises);
      if (responses[0]?.status === "rejected") {
        return;
      }
      checkoutTokenResponse = responses[0].value;
      if (Boolean(responses[1]) && responses[1]?.status === "fulfilled") {
        const parsedAuthData = parseThirdpartyAuthReq(responses[1].value);
        setThirdPartyAuth(parsedAuthData);
      }
      const checkoutId = checkoutTokenResponse?.checkout?.uid;
      if (isUpdate) {
        const discountResponse = await getRequest(`/v1/checkout/${checkoutId}/discounts`, "CHECKOUT_PUBLIC");
        setCoupons(discountResponse);
      }

      const { checkoutUIMetadata } = checkoutContext.state;
      if (Boolean(checkoutUIMetadata?.upsellConfig?.isEnabled)) {
        mutate(`/v1/checkout/${checkoutId}/upsell`);
      }

      const prefillPhone = checkoutTokenResponse?.checkout?.metadata?.user_details?.phone;
      if (prefillPhone) {
        setUserData({ phone: prefillPhone });
      }
    }

    if (!checkoutTokenResponse) return;
    checkoutInit(checkoutTokenResponse);

    const checkoutResponse = checkoutTokenResponse?.checkout;
    setClarityTag(clartityTags.MERCHANT_ID, checkoutResponse?.merchant_id);
    setClarityTag(clartityTags.CHANNEL, checkoutResponse?.metadata?.application_id);

    const accountResponse = checkoutTokenResponse?.customer_attributes ?? {};

    const merchantAttributesResponse = checkoutTokenResponse?.merchant_attributes;
    setMerchantData(merchantAttributesResponse);

    const attributes = merchantAttributesResponse?.account_attributes?.attributes;
    setClarityTag(clartityTags.SHOP_NAME, attributes?.shop_name);

    const ui: any = attributes?.ui;
    if (Boolean(ui)) {
      customBranding(
        ui?.color_pallet?.primary_color,
        ui?.color_pallet?.secondary_color,
        ui?.color_pallet?.primary_text_color,
      );
    }

    const allowedAddressFields: any = checkoutTokenResponse?.merchant_address_config?.config?.fields;
    setAddressConfig(allowedAddressFields);

    const tokenData: any = checkoutTokenResponse?.token;

    const analyticsMeta = checkoutResponse?.metadata?.analytics;
    setThirdPartyAnalyticsData(analyticsMeta);
    setCheckoutChannel(checkoutResponse?.metadata?.application_id);
    setClientMetadata(checkoutResponse?.metadata?.client_meta);
    setLongSessionId(checkoutResponse?.metadata?.long_session_id);

    return {
      checkoutResponse,
      accountResponse,
      tokenData,
      isCheckoutInteractive,
    };
  } catch (error: any) {
    throw error;
  } finally {
    setLockDiscounts(false);
  }
};

export const getItemsTotalAndValue = (items: any, total: number, showOrderSummaryTotal: boolean) => {
  const totalItems = getTotalItems(items);
  let itemsTotalAndValueStr = "";
  itemsTotalAndValueStr = t("total_items", { count: totalItems });
  if (Boolean(showOrderSummaryTotal)) {
    itemsTotalAndValueStr = `${itemsTotalAndValueStr} • ${currencyFormatter(total)}`;
  }
  return itemsTotalAndValueStr;
};

export const isOnlyFreebieAutoApplied = (appliedCoupons: AppliedCouponType[]) => {
  return Boolean(
    appliedCoupons.every(
      (coupon: AppliedCouponType) =>
        coupon?.isFreebie && coupon?.autoApplied && coupon?.discountPercentage === 100,
    ),
  );
};

export const getRedirectUrl = (redirectUrlResponse: string, merchantId: string) => {
  if (Boolean(redirectUrlResponse)) return redirectUrlResponse;
  const TRAYA_MID = "fa2fd878-be4e-4466-aad4-7906b6543422";
  if (merchantId === TRAYA_MID) return `null`;
  return "";
};

export const showShippingChangeToast = (prevValue: number, currValue: number): boolean => {
  return !(prevValue === -1 && currValue === 0) && prevValue !== currValue;
};
