import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter, useSearchParams } from "react-router-dom";
import "./assests/styles/index.css";
import "@shopflo/ui/styles.css"
import { AuthProvider } from "lib/contexts/AuthProvider";
import { UserProvider } from "lib/contexts/UserProvider";
import { CheckoutProvider } from "lib/contexts/CheckoutProvider";
import { MerchantProvider } from "lib/contexts/MerchantProvider";
import { addPolyfills } from "lib/core/polyfills";
import Skeleton from "components/common/loaders/Skeleton";
import { classNames, getRegionFromTimezone, inIframe, isMobile } from "lib/utils/helpers";
import { PaymentProvider } from "lib/contexts/PaymentProvider";
import { LoaderProvider } from "lib/contexts/LoaderContext";
import useSentry from "lib/hooks/useSentry";
import { FpjsProvider, FingerprintJSPro } from "@fingerprintjs/fingerprintjs-pro-react";
import { initialiseNRM } from "lib/third-party/newrelic";
import * as Sentry from "@sentry/react";

const fingerPrintApiKey = import.meta.env.VITE_FINGERPRINT_API_KEY;
const fingerPrintDomain = import.meta.env.VITE_FINGERPRINT_DOMAIN;
const fingerPrintSDKVersion = "v3";
const fingerPrintLoaderVersion = "loader_v3.11.0";
const fingerPrintRegion = getRegionFromTimezone();

addPolyfills();
initialiseNRM()

ReactDOM.render(
  <React.StrictMode>
    <FpjsProvider
      loadOptions={{
        apiKey: fingerPrintApiKey,
        endpoint: [fingerPrintDomain, FingerprintJSPro.defaultEndpoint],
        scriptUrlPattern: [
          `${fingerPrintDomain}/web/${fingerPrintSDKVersion}/${fingerPrintApiKey}/${fingerPrintLoaderVersion}.js`,
          FingerprintJSPro.defaultScriptUrlPattern
        ],
        region: fingerPrintRegion,
      }}>
      <BrowserRouter basename={import.meta.env.VITE_PUBLIC_URL}>
        <AppShell>
          <Suspense fallback={<Skeleton />}>
            <LoaderProvider>
              <AuthProvider>
                <UserProvider>
                  <MerchantProvider>
                    <CheckoutProvider>
                      <PaymentProvider>
                        <App />
                      </PaymentProvider>
                    </CheckoutProvider>
                  </MerchantProvider>
                </UserProvider>
              </AuthProvider>
            </LoaderProvider>
          </Suspense>
        </AppShell>
      </BrowserRouter>
    </FpjsProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);

function AppShell({ children }: { children: React.ReactNode }) {
  const [searchParams] = useSearchParams();
  const isCartPage = searchParams.get("page") === "cart" || searchParams.get("page") === "CART";
  const isSsoLoginPage = searchParams.get("page") === "login-sso";

  useSentry();

  if (isCartPage) {
    return (
      <div className="h-screen w-full overflow-hidden bg-gray-lightest md:h-[85vh] md:w-[25rem] md:rounded-l-[28px] md:rounded-r-none  md:shadow-[0px_0px_10px_0px_rgba(0,0,0,0.1)]">
        {children}
      </div>
    );
  }

  if (isSsoLoginPage) {
    return (
      <div
        className={`flex h-screen w-full overflow-hidden bg-gray-lighter ${
          inIframe() ? `justify-center` : `flex-col items-center justify-center`
        }`}>
        <div
          className={classNames(
            `h-[400px] w-[360px] border border-carbon-lighter bg-white md:h-[400px]`,
            isMobile() ? "rounded-xl" : "relative w-[360px] rounded-[20px] md:w-[360px]",
            inIframe() ? `!w-full !rounded-xl` : ``,
          )}>
          {children}
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames(
        "h-screen w-full overflow-hidden bg-gray-lightest md:h-[85vh] md:w-[25rem] ",
        isMobile() ? "" : inIframe() ? `rounded-[28px]` : "md:rounded-[28px]",
        "md:shadow-[0px_0px_10px_0px_rgba(0,0,0,0.1)]",
      )}>
      {children}
    </div>
  );
}

window.addEventListener('vite:preloadError', () => {
  window.location.reload()
})